import { IconProps } from '@/utilities/types/Icon';

const Facilities = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 21 23"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.5 19.733H4.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C1 18.213 1 17.653 1 16.533V9.298c0-.574 0-.861.074-1.126a2 2 0 0 1 .318-.65c.163-.22.39-.397.843-.75l6.783-5.275c.351-.273.527-.41.72-.462a1 1 0 0 1 .523 0c.194.052.37.189.721.462l6.783 5.275c.453.353.68.53.843.75"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13.545 20.423.326.788c.097.235.255.435.456.575.2.14.434.214.673.214.239 0 .473-.074.673-.214.2-.14.359-.34.456-.575l.326-.788c.116-.28.312-.513.558-.667a1.28 1.28 0 0 1 .821-.187l.798.091c.238.027.478-.02.69-.137.213-.117.39-.297.51-.52a1.406 1.406 0 0 0-.07-1.444l-.474-.698a1.533 1.533 0 0 1-.256-.861c0-.308.09-.608.259-.857l.473-.698a1.407 1.407 0 0 0 .07-1.445 1.278 1.278 0 0 0-.509-.52 1.162 1.162 0 0 0-.69-.137l-.799.092a1.28 1.28 0 0 1-.82-.188 1.424 1.424 0 0 1-.558-.67l-.33-.788a1.307 1.307 0 0 0-.455-.575c-.2-.14-.434-.214-.673-.214-.239 0-.473.074-.673.214-.2.14-.359.34-.456.575l-.326.788a1.424 1.424 0 0 1-.558.67c-.249.154-.536.22-.821.188l-.8-.092a1.162 1.162 0 0 0-.691.137c-.213.117-.39.297-.51.52a1.406 1.406 0 0 0 .071 1.445l.473.698c.169.249.26.549.26.857 0 .308-.091.608-.26.857l-.473.698a1.406 1.406 0 0 0-.07 1.445c.12.222.296.403.509.52.213.116.453.163.69.137l.799-.092a1.28 1.28 0 0 1 .82.188c.248.154.445.388.56.67Z"
      />
      <circle cx="15" cy="16" r="2" fill="currentColor" />
    </svg>
  );
};

export default Facilities;
